@layer reset, base, tokens, recipes, utilities;

/* @import url("./reset.scss"); */

html {
  scroll-behavior: smooth;
}

body {
  /* Removes the "elastic" effect when scrolling to the top/bottom of the page on Mac */

  /* overscroll-behavior-y: none; */
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.document {
  background-color: white;
  color: $text-primary;
  color: #878993;
  color: var(--colors-primary-1);
}

:where(h1, h2, h3, h4, h5, h6) {
  color: $text-primary;
}

.custom-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 24px;
}

br {
  content: "";
  display: block;
  height: 1em;
}

.container {
  width: 100%;
  margin: auto;
  max-width: 1280px;
  padding: 0 1.5rem;
}

/* @media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
} */
