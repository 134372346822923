/* @import url("./reset.scss"); *//* @media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
} */html {
  scroll-behavior: smooth;
}body {
  /* Removes the "elastic" effect when scrolling to the top/bottom of the page on Mac */
  /* overscroll-behavior-y: none; */
}.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}.document {
  background-color: white;
  color: $text-primary;
  color: #878993;
  color: var(--colors-primary-1);
}:where(h1, h2, h3, h4, h5, h6) {
  color: $text-primary;
}.custom-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 24px;
}br {
  content: "";
  display: block;
  height: 1em;
}.container {
  width: 100%;
  margin: auto;
  max-width: 1280px;
  padding: 0 1.5rem;
}@layer reset, base, tokens, recipes, utilities;@layer base{
  :root{
    --made-with-panda:'🐼' }

  *,::backdrop,:after,:before{
    --blur: ;
    --brightness: ;
    --contrast: ;
    --grayscale: ;
    --hue-rotate: ;
    --invert: ;
    --saturate: ;
    --sepia: ;
    --drop-shadow: ;
    --backdrop-blur: ;
    --backdrop-brightness: ;
    --backdrop-contrast: ;
    --backdrop-grayscale: ;
    --backdrop-hue-rotate: ;
    --backdrop-invert: ;
    --backdrop-opacity: ;
    --backdrop-saturate: ;
    --backdrop-sepia: ;
    --scroll-snap-strictness:proximity;
    --border-spacing-x:0;
    --border-spacing-y:0;
    --translate-x:0;
    --translate-y:0;
    --rotate:0;
    --skew-x:0;
    --skew-y:0;
    --scale-x:1;
    --scale-y:1 }

  body{
    font-family:var(--fonts-body)}}@layer reset{
  *{
    margin:0;
    padding:0;
    font:inherit}

  *,:after,:before{
    box-sizing:border-box;
    border-width:0;
    border-style:solid;
    border-color:var(--global-color-border,currentColor)}

  html{
    line-height:1.5;
    --font-fallback:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    -webkit-text-size-adjust:100%;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    -moz-tab-size:4;
    -o-tab-size:4;
       tab-size:4;
    font-family:var(--global-font-body,var(--font-fallback))}

  hr{
    height:0;
    color:inherit;
    border-top-width:1px}

  body{
    height:100%;
    line-height:inherit}

  img{
    border-style:none}

  audio,canvas,embed,iframe,img,object,svg,video{
    display:block;
    vertical-align:middle}

  img,video{
    max-width:100%;
    height:auto}

  h1,h2,h3,h4,h5,h6,p{
    overflow-wrap:break-word}

  ol,ul{
    list-style:none}

  [type=button],[type=reset],[type=submit],button{
    -webkit-appearance:button;
    background-color:transparent;
    background-image:none}

  button,input,optgroup,select,textarea{
    color:inherit}

  button,select{
    text-transform:none}

  table{
    text-indent:0;
    border-color:inherit;
    border-collapse:collapse}

  input::-moz-placeholder, textarea::-moz-placeholder{
    opacity:1;
    color:var(--global-color-placeholder,#9ca3af)}

  input::placeholder,textarea::placeholder{
    opacity:1;
    color:var(--global-color-placeholder,#9ca3af)}

  textarea{
    resize:vertical}

  summary{
    display:list-item}

  small{
    font-size:80%}

  sub,sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline}

  sub{
    bottom:-0.25em}

  sup{
    top:-0.5em}

  dialog{
    padding:0}

  a{
    color:inherit;
    text-decoration:inherit}

  abbr:where([title]){
    -webkit-text-decoration:underline dotted;
            text-decoration:underline dotted}

  b,strong{
    font-weight:bolder}

  code,kbd,pre,samp{
    font-size:1em;
    --font-mono-fallback:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New';
    font-family:var(--global-font-mono,var(--font-fallback))}

  input[type=email],input[type=password],input[type=search],input[type=text]{
    -webkit-appearance:none;
    -moz-appearance:none}

  input[type=search]{
    -webkit-appearance:textfield;
    outline-offset:-2px}

  ::-webkit-search-cancel-button,::-webkit-search-decoration{
    -webkit-appearance:none}

  ::-webkit-file-upload-button{
    -webkit-appearance:button;
    font:inherit}

  input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{
    height:auto}

  input[type=number]{
    -moz-appearance:textfield}

  :-moz-ui-invalid{
    box-shadow:none}

  :-moz-focusring{
    outline:auto}}@layer tokens{
  :where(:root,:host){
    --borders-none:none;
    --easings-default:cubic-bezier(0.4,0,0.2,1);
    --easings-linear:linear;
    --easings-in:cubic-bezier(0.4,0,1,1);
    --easings-out:cubic-bezier(0,0,0.2,1);
    --easings-in-out:cubic-bezier(0.4,0,0.2,1);
    --durations-fastest:50ms;
    --durations-faster:100ms;
    --durations-fast:150ms;
    --durations-normal:200ms;
    --durations-slow:300ms;
    --durations-slower:400ms;
    --durations-slowest:500ms;
    --radii-xs:0.125rem;
    --radii-sm:0.25rem;
    --radii-md:0.375rem;
    --radii-lg:0.5rem;
    --radii-xl:0.75rem;
    --radii-2xl:1rem;
    --radii-3xl:1.5rem;
    --radii-full:9999px;
    --font-weights-thin:100;
    --font-weights-extralight:200;
    --font-weights-light:300;
    --font-weights-normal:400;
    --font-weights-medium:500;
    --font-weights-semibold:600;
    --font-weights-bold:700;
    --font-weights-extrabold:800;
    --font-weights-black:900;
    --line-heights-none:1;
    --line-heights-tight:1.25;
    --line-heights-snug:1.375;
    --line-heights-normal:1.5;
    --line-heights-relaxed:1.625;
    --line-heights-loose:2;
    --letter-spacings-tighter:-0.05em;
    --letter-spacings-tight:-0.025em;
    --letter-spacings-normal:0em;
    --letter-spacings-wide:0.025em;
    --letter-spacings-wider:0.05em;
    --letter-spacings-widest:0.1em;
    --font-sizes-2xs:0.5rem;
    --font-sizes-xs:0.75rem;
    --font-sizes-sm:0.875rem;
    --font-sizes-md:1rem;
    --font-sizes-lg:1.125rem;
    --font-sizes-xl:1.25rem;
    --font-sizes-2xl:1.5rem;
    --font-sizes-3xl:1.875rem;
    --font-sizes-4xl:2.25rem;
    --font-sizes-5xl:3rem;
    --font-sizes-6xl:3.75rem;
    --font-sizes-7xl:4.5rem;
    --font-sizes-8xl:6rem;
    --font-sizes-9xl:8rem;
    --shadows-xs:0 1px 2px 0 rgb(0 0 0/0.05);
    --shadows-sm:0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
    --shadows-md:0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
    --shadows-lg:0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    --shadows-xl:0 20px 25px -5px rgb(0 0 0/0.1),0 8px 10px -6px rgb(0 0 0/0.1);
    --shadows-2xl:0 25px 50px -12px rgb(0 0 0/0.25);
    --shadows-inner:inset 0 2px 4px 0 rgb(0 0 0/0.05);
    --colors-current:currentColor;
    --colors-black:#000;
    --colors-white:#fff;
    --colors-transparent:rgb(0 0 0/0);
    --colors-rose-50:#fff1f2;
    --colors-rose-100:#ffe4e6;
    --colors-rose-200:#fecdd3;
    --colors-rose-300:#fda4af;
    --colors-rose-400:#fb7185;
    --colors-rose-500:#f43f5e;
    --colors-rose-600:#e11d48;
    --colors-rose-700:#be123c;
    --colors-rose-800:#9f1239;
    --colors-rose-900:#881337;
    --colors-rose-950:#4c0519;
    --colors-pink-50:#fdf2f8;
    --colors-pink-100:#fce7f3;
    --colors-pink-200:#fbcfe8;
    --colors-pink-300:#f9a8d4;
    --colors-pink-400:#f472b6;
    --colors-pink-500:#ec4899;
    --colors-pink-600:#db2777;
    --colors-pink-700:#be185d;
    --colors-pink-800:#9d174d;
    --colors-pink-900:#831843;
    --colors-pink-950:#500724;
    --colors-fuchsia-50:#fdf4ff;
    --colors-fuchsia-100:#fae8ff;
    --colors-fuchsia-200:#f5d0fe;
    --colors-fuchsia-300:#f0abfc;
    --colors-fuchsia-400:#e879f9;
    --colors-fuchsia-500:#d946ef;
    --colors-fuchsia-600:#c026d3;
    --colors-fuchsia-700:#a21caf;
    --colors-fuchsia-800:#86198f;
    --colors-fuchsia-900:#701a75;
    --colors-fuchsia-950:#4a044e;
    --colors-purple-50:#faf5ff;
    --colors-purple-100:#f3e8ff;
    --colors-purple-200:#e9d5ff;
    --colors-purple-300:#d8b4fe;
    --colors-purple-400:#c084fc;
    --colors-purple-500:#a855f7;
    --colors-purple-600:#9333ea;
    --colors-purple-700:#7e22ce;
    --colors-purple-800:#6b21a8;
    --colors-purple-900:#581c87;
    --colors-purple-950:#3b0764;
    --colors-violet-50:#f5f3ff;
    --colors-violet-100:#ede9fe;
    --colors-violet-200:#ddd6fe;
    --colors-violet-300:#c4b5fd;
    --colors-violet-400:#a78bfa;
    --colors-violet-500:#8b5cf6;
    --colors-violet-600:#7c3aed;
    --colors-violet-700:#6d28d9;
    --colors-violet-800:#5b21b6;
    --colors-violet-900:#4c1d95;
    --colors-violet-950:#2e1065;
    --colors-indigo-50:#eef2ff;
    --colors-indigo-100:#e0e7ff;
    --colors-indigo-200:#c7d2fe;
    --colors-indigo-300:#a5b4fc;
    --colors-indigo-400:#818cf8;
    --colors-indigo-500:#6366f1;
    --colors-indigo-600:#4f46e5;
    --colors-indigo-700:#4338ca;
    --colors-indigo-800:#3730a3;
    --colors-indigo-900:#312e81;
    --colors-indigo-950:#1e1b4b;
    --colors-blue-50:#eff6ff;
    --colors-blue-100:#dbeafe;
    --colors-blue-200:#bfdbfe;
    --colors-blue-300:#93c5fd;
    --colors-blue-400:#60a5fa;
    --colors-blue-500:#3b82f6;
    --colors-blue-600:#2563eb;
    --colors-blue-700:#1d4ed8;
    --colors-blue-800:#1e40af;
    --colors-blue-900:#1e3a8a;
    --colors-blue-950:#172554;
    --colors-sky-50:#f0f9ff;
    --colors-sky-100:#e0f2fe;
    --colors-sky-200:#bae6fd;
    --colors-sky-300:#7dd3fc;
    --colors-sky-400:#38bdf8;
    --colors-sky-500:#0ea5e9;
    --colors-sky-600:#0284c7;
    --colors-sky-700:#0369a1;
    --colors-sky-800:#075985;
    --colors-sky-900:#0c4a6e;
    --colors-sky-950:#082f49;
    --colors-cyan-50:#ecfeff;
    --colors-cyan-100:#cffafe;
    --colors-cyan-200:#a5f3fc;
    --colors-cyan-300:#67e8f9;
    --colors-cyan-400:#22d3ee;
    --colors-cyan-500:#06b6d4;
    --colors-cyan-600:#0891b2;
    --colors-cyan-700:#0e7490;
    --colors-cyan-800:#155e75;
    --colors-cyan-900:#164e63;
    --colors-cyan-950:#083344;
    --colors-teal-50:#f0fdfa;
    --colors-teal-100:#ccfbf1;
    --colors-teal-200:#99f6e4;
    --colors-teal-300:#5eead4;
    --colors-teal-400:#2dd4bf;
    --colors-teal-500:#14b8a6;
    --colors-teal-600:#0d9488;
    --colors-teal-700:#0f766e;
    --colors-teal-800:#115e59;
    --colors-teal-900:#134e4a;
    --colors-teal-950:#042f2e;
    --colors-emerald-50:#ecfdf5;
    --colors-emerald-100:#d1fae5;
    --colors-emerald-200:#a7f3d0;
    --colors-emerald-300:#6ee7b7;
    --colors-emerald-400:#34d399;
    --colors-emerald-500:#10b981;
    --colors-emerald-600:#059669;
    --colors-emerald-700:#047857;
    --colors-emerald-800:#065f46;
    --colors-emerald-900:#064e3b;
    --colors-emerald-950:#022c22;
    --colors-green-50:#f0fdf4;
    --colors-green-100:#dcfce7;
    --colors-green-200:#bbf7d0;
    --colors-green-300:#86efac;
    --colors-green-400:#4ade80;
    --colors-green-500:#22c55e;
    --colors-green-600:#16a34a;
    --colors-green-700:#15803d;
    --colors-green-800:#166534;
    --colors-green-900:#14532d;
    --colors-green-950:#052e16;
    --colors-lime-50:#f7fee7;
    --colors-lime-100:#ecfccb;
    --colors-lime-200:#d9f99d;
    --colors-lime-300:#bef264;
    --colors-lime-400:#a3e635;
    --colors-lime-500:#84cc16;
    --colors-lime-600:#65a30d;
    --colors-lime-700:#4d7c0f;
    --colors-lime-800:#3f6212;
    --colors-lime-900:#365314;
    --colors-lime-950:#1a2e05;
    --colors-yellow-50:#fefce8;
    --colors-yellow-100:#fef9c3;
    --colors-yellow-200:#fef08a;
    --colors-yellow-300:#fde047;
    --colors-yellow-400:#facc15;
    --colors-yellow-500:#eab308;
    --colors-yellow-600:#ca8a04;
    --colors-yellow-700:#a16207;
    --colors-yellow-800:#854d0e;
    --colors-yellow-900:#713f12;
    --colors-yellow-950:#422006;
    --colors-amber-50:#fffbeb;
    --colors-amber-100:#fef3c7;
    --colors-amber-200:#fde68a;
    --colors-amber-300:#fcd34d;
    --colors-amber-400:#fbbf24;
    --colors-amber-500:#f59e0b;
    --colors-amber-600:#d97706;
    --colors-amber-700:#b45309;
    --colors-amber-800:#92400e;
    --colors-amber-900:#78350f;
    --colors-amber-950:#451a03;
    --colors-orange-50:#fff7ed;
    --colors-orange-100:#ffedd5;
    --colors-orange-200:#fed7aa;
    --colors-orange-300:#fdba74;
    --colors-orange-400:#fb923c;
    --colors-orange-500:#f97316;
    --colors-orange-600:#ea580c;
    --colors-orange-700:#c2410c;
    --colors-orange-800:#9a3412;
    --colors-orange-900:#7c2d12;
    --colors-orange-950:#431407;
    --colors-red-50:#fef2f2;
    --colors-red-100:#fee2e2;
    --colors-red-200:#fecaca;
    --colors-red-300:#fca5a5;
    --colors-red-400:#f87171;
    --colors-red-500:#ef4444;
    --colors-red-600:#dc2626;
    --colors-red-700:#b91c1c;
    --colors-red-800:#991b1b;
    --colors-red-900:#7f1d1d;
    --colors-red-950:#450a0a;
    --colors-neutral-50:#fafafa;
    --colors-neutral-100:#f5f5f5;
    --colors-neutral-200:#e5e5e5;
    --colors-neutral-300:#d4d4d4;
    --colors-neutral-400:#a3a3a3;
    --colors-neutral-500:#737373;
    --colors-neutral-600:#525252;
    --colors-neutral-700:#404040;
    --colors-neutral-800:#262626;
    --colors-neutral-900:#171717;
    --colors-neutral-950:#0a0a0a;
    --colors-stone-50:#fafaf9;
    --colors-stone-100:#f5f5f4;
    --colors-stone-200:#e7e5e4;
    --colors-stone-300:#d6d3d1;
    --colors-stone-400:#a8a29e;
    --colors-stone-500:#78716c;
    --colors-stone-600:#57534e;
    --colors-stone-700:#44403c;
    --colors-stone-800:#292524;
    --colors-stone-900:#1c1917;
    --colors-stone-950:#0c0a09;
    --colors-zinc-50:#fafafa;
    --colors-zinc-100:#f4f4f5;
    --colors-zinc-200:#e4e4e7;
    --colors-zinc-300:#d4d4d8;
    --colors-zinc-400:#a1a1aa;
    --colors-zinc-500:#71717a;
    --colors-zinc-600:#52525b;
    --colors-zinc-700:#3f3f46;
    --colors-zinc-800:#27272a;
    --colors-zinc-900:#18181b;
    --colors-zinc-950:#09090b;
    --colors-gray-50:#f9fafb;
    --colors-gray-100:#f3f4f6;
    --colors-gray-200:#e5e7eb;
    --colors-gray-300:#d1d5db;
    --colors-gray-400:#9ca3af;
    --colors-gray-500:#6b7280;
    --colors-gray-600:#4b5563;
    --colors-gray-700:#374151;
    --colors-gray-800:#1f2937;
    --colors-gray-900:#111827;
    --colors-gray-950:#030712;
    --colors-slate-50:#f8fafc;
    --colors-slate-100:#f1f5f9;
    --colors-slate-200:#e2e8f0;
    --colors-slate-300:#cbd5e1;
    --colors-slate-400:#94a3b8;
    --colors-slate-500:#64748b;
    --colors-slate-600:#475569;
    --colors-slate-700:#334155;
    --colors-slate-800:#1e293b;
    --colors-slate-900:#0f172a;
    --colors-slate-950:#020617;
    --blurs-sm:4px;
    --blurs-base:8px;
    --blurs-md:12px;
    --blurs-lg:16px;
    --blurs-xl:24px;
    --blurs-2xl:40px;
    --blurs-3xl:64px;
    --spacing-0:0rem;
    --spacing-1:0.25rem;
    --spacing-2:0.5rem;
    --spacing-3:0.75rem;
    --spacing-4:1rem;
    --spacing-5:1.25rem;
    --spacing-6:1.5rem;
    --spacing-7:1.75rem;
    --spacing-8:2rem;
    --spacing-9:2.25rem;
    --spacing-10:2.5rem;
    --spacing-11:2.75rem;
    --spacing-12:3rem;
    --spacing-14:3.5rem;
    --spacing-16:4rem;
    --spacing-20:5rem;
    --spacing-24:6rem;
    --spacing-28:7rem;
    --spacing-32:8rem;
    --spacing-36:9rem;
    --spacing-40:10rem;
    --spacing-44:11rem;
    --spacing-48:12rem;
    --spacing-52:13rem;
    --spacing-56:14rem;
    --spacing-60:15rem;
    --spacing-64:16rem;
    --spacing-72:18rem;
    --spacing-80:20rem;
    --spacing-96:24rem;
    --spacing-0\.5:0.125rem;
    --spacing-1\.5:0.375rem;
    --spacing-2\.5:0.625rem;
    --spacing-3\.5:0.875rem;
    --sizes-0:0rem;
    --sizes-1:0.25rem;
    --sizes-2:0.5rem;
    --sizes-3:0.75rem;
    --sizes-4:1rem;
    --sizes-5:1.25rem;
    --sizes-6:1.5rem;
    --sizes-7:1.75rem;
    --sizes-8:2rem;
    --sizes-9:2.25rem;
    --sizes-10:2.5rem;
    --sizes-11:2.75rem;
    --sizes-12:3rem;
    --sizes-14:3.5rem;
    --sizes-16:4rem;
    --sizes-20:5rem;
    --sizes-24:6rem;
    --sizes-28:7rem;
    --sizes-32:8rem;
    --sizes-36:9rem;
    --sizes-40:10rem;
    --sizes-44:11rem;
    --sizes-48:12rem;
    --sizes-52:13rem;
    --sizes-56:14rem;
    --sizes-60:15rem;
    --sizes-64:16rem;
    --sizes-72:18rem;
    --sizes-80:20rem;
    --sizes-96:24rem;
    --sizes-0\.5:0.125rem;
    --sizes-1\.5:0.375rem;
    --sizes-2\.5:0.625rem;
    --sizes-3\.5:0.875rem;
    --sizes-xs:20rem;
    --sizes-sm:24rem;
    --sizes-md:28rem;
    --sizes-lg:32rem;
    --sizes-xl:36rem;
    --sizes-2xl:42rem;
    --sizes-3xl:48rem;
    --sizes-4xl:56rem;
    --sizes-5xl:64rem;
    --sizes-6xl:72rem;
    --sizes-7xl:80rem;
    --sizes-8xl:90rem;
    --sizes-prose:65ch;
    --sizes-full:100%;
    --sizes-min:min-content;
    --sizes-max:max-content;
    --sizes-fit:fit-content;
    --sizes-breakpoint-sm:640px;
    --sizes-breakpoint-md:768px;
    --sizes-breakpoint-lg:1024px;
    --sizes-breakpoint-xl:1280px;
    --sizes-breakpoint-2xl:1536px;
    --animations-spin:spin 1s linear infinite;
    --animations-ping:ping 1s cubic-bezier(0,0,0.2,1) infinite;
    --animations-pulse:pulse 2s cubic-bezier(0.4,0,0.6,1) infinite;
    --animations-bounce:bounce 1s infinite;
    --fonts-sans:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --fonts-serif:ui-serif,Georgia,Cambria,"Times New Roman",Times,serif;
    --fonts-mono:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --fonts-body:var(--font-body),monospace;
    --fonts-heading:var(--font-heading),sans-serif;
    --breakpoints-sm:640px;
    --breakpoints-md:768px;
    --breakpoints-lg:1024px;
    --breakpoints-xl:1280px;
    --breakpoints-2xl:1536px;
    --colors-primary-1:#222220;
    --colors-primary-2:#1800D9;
    --colors-primary-3:#9FAFFF;
    --colors-primary-4:#8C60FA;
    --colors-secondary-1:#0CDCB7;
    --colors-secondary-2:#9DEDDD;
    --colors-secondary-3:#FF9331;
    --colors-secondary-4:#FFD8A6;
    --colors-other-1:#000A3A;
    --colors-other-2:#060086;
    --colors-other-3:#D6DDFF;
    --colors-other-4:#B496FF;
    --colors-other-5:#000;
    --colors-other-6:#6332DF;
    --colors-other-7:#E8E5FB;
    --colors-other-8:#FFF4EA;
    --colors-other-9:#F3EFFF;
    --colors-other-10:#E7FCF8;
    --gradients-button-1:linear-gradient(90deg,#9FAFFF 0%,#9EAAFF 3.645%,#9482FC 23.945%,#8E69FB 40.27%,#8C60FA 50%,#8E69FB 59.73%,#9482FC 76.055%,#9EAAFF 96.355%,#9FAFFF 100%);
    --gradients-button-2:linear-gradient(90deg,#FFB48A -20.765%,#6451FF 50%,#FFB48A 120.765%);
    --gradients-button-3:linear-gradient(90deg,#4AFFDF,#5544DF) border-box;
    --gradients-button-4:linear-gradient(90deg,#DFACFF,#8C60FA) border-box }

  @keyframes spin{
    to{
      transform:rotate(360deg)}}

  @keyframes ping{
    75%,to{
      transform:scale(2);
      opacity:0}}

  @keyframes pulse{
    50%{
      opacity:.5}}

  @keyframes bounce{
    0%,to{
      transform:translateY(-25%);
      animation-timing-function:cubic-bezier(0.8,0,1,1)}

    50%{
      transform:none;
      animation-timing-function:cubic-bezier(0,0,0.2,1)}}}@layer utilities{
  .p_32px_14px_28px{
    padding:32px 14px 28px}

  .bg_center_55\%{
    background-position:center 55%}

  .tracking_-0\.418px{
    letter-spacing:-0.418px}

  .mb_56px{
    margin-bottom:56px}

  .mt_8{
    margin-top:var(--spacing-8)}

  .mt_10{
    margin-top:var(--spacing-10)}

  .mb_7{
    margin-bottom:var(--spacing-7)}

  .p_40px_32px{
    padding:40px 32px}

  .leading_146\%{
    line-height:146%}

  .gap_100px{
    gap:100px}

  .max-w_650px{
    max-width:650px}

  .m_auto{
    margin:auto}

  .text_\#B5B5FF{
    color:#B5B5FF}

  .leading_157\%{
    line-height:157%}

  .gap_3{
    gap:var(--spacing-3)}

  .h_0{
    height:var(--sizes-0)}

  .transform_translateY\(-150px\){
    transform:translateY(-150px)}

  .bg_\#F5F7FF{
    background:#F5F7FF}

  .page\:white-background_true{
    white-background:true}

  .pb_120px{
    padding-bottom:120px}

  .bg_linear-gradient\(to_bottom\,_transparent_50\%\,_rgba\(140\,_96\,_250\,_\.4\)\){
    background:linear-gradient(to bottom,transparent 50%,rgba(140,96,250,.4))}

  .bg_linear-gradient\(180deg\,_\#2C2089_-22\.65\%\,_\#000A3A_67\.42\%\){
    background:linear-gradient(180deg,#2C2089 -22.65%,#000A3A 67.42%)}

  .pt_72px{
    padding-top:72px}

  .pb_10{
    padding-bottom:var(--spacing-10)}

  .w_209px{
    width:209px}

  .h_42px{
    height:42px}

  .mb_80px{
    margin-bottom:80px}

  .h_56px{
    height:56px}

  .h_110px{
    height:110px}

  .h_75px{
    height:75px}

  .leading_24px{
    line-height:24px}

  .gap_90px{
    gap:90px}

  .gap-y_10{
    -moz-column-gap:var(--spacing-10);
         column-gap:var(--spacing-10)}

  .gap_88px{
    gap:88px}

  .w_150px{
    width:150px}

  .\[\&_span\]\:font_bold span{
    font-weight:var(--font-weights-bold)}

  .\[\&_a\]\:opacity_0\.9 a{
    opacity:0.9}

  .gap_56px{
    gap:56px}

  .gap-x_3{
    row-gap:var(--spacing-3)}

  .gap_72px{
    gap:72px}

  .opacity_0\.9{
    opacity:0.9}

  .direction_column{
    direction:column}

  .gap_20{
    gap:var(--spacing-20)}

  .mb_60px{
    margin-bottom:60px}

  .mt_20{
    margin-top:var(--spacing-20)}

  .py_20{
    padding-block:var(--spacing-20)}

  .py_24px{
    padding-block:24px}

  .bg-img_url\(\/textures\/fullWidthBanner-bg\.svg\)\,_linear-gradient\(91deg\,_\#0000CF_0\%\,_\#060086_100\%\){
    background-image:url(/textures/fullWidthBanner-bg.svg),linear-gradient(91deg,#0000CF 0%,#060086 100%)}

  .text_other\.3{
    color:var(--colors-other-3)}

  .fs_11px{
    font-size:11px}

  .gap_16px{
    gap:16px}

  .gap_8px{
    gap:8px}

  .max-w_725px{
    max-width:725px}

  .transform_scale\(1\.1\){
    transform:scale(1.1)}

  .origin_left_center{
    transform-origin:left center}

  .mb_48px{
    margin-bottom:48px}

  .gap-x_6{
    row-gap:var(--spacing-6)}

  .\[\&_\>_\:nth-child\(even\)\]\:bg_\#DCE2FF>:nth-child(2n){
    background-color:#DCE2FF}

  .\[\&_\>_\:nth-child\(odd\)\]\:bg_\#DCE2FFA8>:nth-child(odd){
    background-color:#DCE2FFA8}

  .pos_sticky{
    position:sticky}

  .top_80px{
    top:80px}

  .bg_\#E8ECFF\!{
    background-color:#E8ECFF!important}

  .z_10{
    z-index:10}

  .pt_var\(--pt\,_100px\){
    padding-top:var(--pt,100px)}

  .pb_var\(--pb\,_120px\){
    padding-bottom:var(--pb,120px)}

  .top_-250px{
    top:-250px}

  .text_\#333B62{
    color:#333B62}

  .flex_1_0_auto{
    flex:1 0 auto}

  .rounded_5px{
    border-radius:5px}

  .bg_linear-gradient\(109deg\,_\#E7E8FF_-15\.46\%\,_\#9085DA_204\.87\%\){
    background:linear-gradient(109deg,#E7E8FF -15.46%,#9085DA 204.87%)}

  .text_\#672EF4{
    color:#672EF4}

  .tracking_-0\.266px{
    letter-spacing:-0.266px}

  .my_16px{
    margin-block:16px}

  .\--thickness_1px{
    --thickness:1px }

  .border-block-end-width_var\(--thickness\){
    border-block-end-width:var(--thickness)}

  .border_\#D4D6E4{
    border-color:#D4D6E4}

  .justify_start{
    justify-content:start}

  .gap_48px{
    gap:48px}

  .py_9{
    padding-block:var(--spacing-9)}

  .gap-y_72px{
    -moz-column-gap:72px;
         column-gap:72px}

  .gap-x_92px{
    row-gap:92px}

  .mt_100px{
    margin-top:100px}

  .max-w_340px{
    max-width:340px}

  .bg_cover\!{
    background-size:cover!important}

  .bg_center\!{
    background-position:center!important}

  .p_72px_76px_108px_28px{
    padding:72px 76px 108px 28px}

  .bg_url\(\/textures\/integration-left-bg\.svg\)\,_\#F4F6FF{
    background:url(/textures/integration-left-bg.svg),#F4F6FF}

  .p_70px_24px_92px_80px{
    padding:70px 24px 92px 80px}

  .bg_url\(\/textures\/integration-right-bg\.svg\)\,_\#F4F6FF{
    background:url(/textures/integration-right-bg.svg),#F4F6FF}

  .tracking_-0\.19px{
    letter-spacing:-0.19px}

  .pos_left{
    position:left}

  .pos_right{
    position:right}

  .pt_60px{
    padding-top:60px}

  .pb_72px{
    padding-bottom:72px}

  .bg-img_url\(\/stats-bg\.svg\)\,_linear-gradient\(91deg\,_\#0000CF_0\%\,_\#060086_100\%\){
    background-image:url(/stats-bg.svg),linear-gradient(91deg,#0000CF 0%,#060086 100%)}

  .bg_center_65\%{
    background-position:center 65%}

  .gap_9\%{
    gap:9%}

  .pt_calc\(var\(--pt\)_\+_120px\){
    padding-top:calc(var(--pt) + 120px)}

  .mt_-120px{
    margin-top:-120px}

  .text_\#B496FF{
    color:#B496FF}

  .\[\&_em\]\:pos_relative em{
    position:relative}

  .\[\&_em\]\:text_transparent em{
    color:var(--colors-transparent)}

  .\[\&_em\]\:\[\&\:after\]\:content_attr\(data-text\) em:after{
    content:attr(data-text)}

  .\[\&_em\]\:\[\&\:after\]\:d_inline-flex em:after{
    display:inline-flex}

  .\[\&_em\]\:\[\&\:after\]\:items_center em:after{
    align-items:center}

  .\[\&_em\]\:\[\&\:after\]\:justify_center em:after{
    justify-content:center}

  .\[\&_em\]\:\[\&\:after\]\:text_white em:after{
    color:var(--colors-white)}

  .\[\&_em\]\:\[\&\:after\]\:pos_absolute em:after{
    position:absolute}

  .\[\&_em\]\:\[\&\:after\]\:top_50\% em:after{
    top:50%}

  .\[\&_em\]\:\[\&\:after\]\:left_50\% em:after{
    left:50%}

  .\[\&_em\]\:\[\&\:after\]\:transform_translate\(-50\%\,_-50\%\) em:after{
    transform:translate(-50%,-50%)}

  .\[\&_em\]\:\[\&\:after\]\:w_110\% em:after{
    width:110%}

  .\[\&_em\]\:\[\&\:after\]\:h_80\% em:after{
    height:80%}

  .\[\&_em\]\:\[\&\:after\]\:bg_linear-gradient\(270deg\,_\#FFB48A_0\%\,_rgba\(218\,_166\,_251\,_0\.00\)_94\.48\%\) em:after{
    background:linear-gradient(270deg,#FFB48A 0%,rgba(218,166,251,0.00) 94.48%)}

  .\[\&_em\]\:\[\&\:after\]\:rounded_110px em:after{
    border-radius:110px}

  .\[\&_em\]\:\[\&\:after\]\:z_0 em:after{
    z-index:0}

  .min-w_min\(110\%\,_80vw\){
    min-width:min(110%,80vw)}

  .mr_-10\%{
    margin-right:-10%}

  .gap-y_20{
    -moz-column-gap:var(--spacing-20);
         column-gap:var(--spacing-20)}

  .gap-x_10{
    row-gap:var(--spacing-10)}

  .text_\#F2F2F5{
    color:#F2F2F5}

  .max-w_637px{
    max-width:637px}

  .justify-self_start{
    justify-self:start}

  .fs_8px{
    font-size:8px}

  .w_max-content{
    width:-moz-max-content;
    width:max-content}

  .\[\&\:last-of-type\]\:pr_6:last-of-type{
    padding-right:var(--spacing-6)}

  .h_var\(--height-m\,_20px\){
    height:var(--height-m,20px)}

  .grid-cols_1fr_4fr{
    grid-template-columns:1fr 4fr}

  .place-items_center{
    place-items:center}

  .py_3{
    padding-block:var(--spacing-3)}

  .px_6{
    padding-inline:var(--spacing-6)}

  .bg_\#fff{
    background:#fff}

  .p_18px_10px_20px{
    padding:18px 10px 20px}

  .bg-gradient_var\(--bgGradient\){
    --gradient-stops:var(--gradient-from),var(--gradient-to);
    --gradient:var(--gradient-via-stops,var(--gradient-stops));
    background-image:var(--bgGradient)}

  .p_8px_16px{
    padding:8px 16px}

  .rounded_11px{
    border-radius:11px}

  .\[\&_em\]\:font_bold em{
    font-weight:var(--font-weights-bold)}

  .gap_24px{
    gap:24px}

  .bg_linear-gradient\(180deg\,_rgba\(220\,_226\,_255\,_0\.19\)_0\%\,_rgba\(24\,_0\,_217\,_0\.19\)_100\%\){
    background:linear-gradient(180deg,rgba(220,226,255,0.19) 0%,rgba(24,0,217,0.19) 100%)}

  .mb_8px{
    margin-bottom:8px}

  .min-w_450px{
    min-width:450px}

  .w_250\%{
    width:250%}

  .max-w_none{
    max-width:none}

  .pointer-events_none{
    pointer-events:none}

  .border_currentcolor{
    border-color:currentcolor}

  .fs_28px{
    font-size:28px}

  .opacity_0\.6{
    opacity:0.6}

  .mt_32px{
    margin-top:32px}

  .max-w_740px{
    max-width:740px}

  .min-w_125\%{
    min-width:125%}

  .left_50\%{
    left:50%}

  .transform_translate\(-50\%\,_-50\%\){
    transform:translate(-50%,-50%)}

  .text_primary\.2{
    color:var(--colors-primary-2)}

  .border_2px_solid_\#9FAFFF{
    border:2px solid #9FAFFF}

  .\[\&_em\]\:font_700 em{
    font-weight:700}

  .\[\&_em\]\:bg_linear-gradient\(270deg\,_\#FFB48A_0\%\,_rgba\(218\,_166\,_251\,_0\.00\)_94\.48\%\) em{
    background:linear-gradient(270deg,#FFB48A 0%,rgba(218,166,251,0.00) 94.48%)}

  .fs_30px{
    font-size:30px}

  .mb_8{
    margin-bottom:var(--spacing-8)}

  .\[\&_em\]\:leading_46px em{
    line-height:46px}

  .\[\&_em\]\:px_6px em{
    padding-inline:6px}

  .\[\&_em\]\:py_1px em{
    padding-block:1px}

  .\[\&_em\]\:mx_-6px em{
    margin-inline:-6px}

  .gap_44px{
    gap:44px}

  .pt_var\(--pt\,_85px\){
    padding-top:var(--pt,85px)}

  .pb_var\(--pb\,_85px\){
    padding-bottom:var(--pb,85px)}

  .gap-x_48px{
    row-gap:48px}

  .gap-y_8{
    -moz-column-gap:var(--spacing-8);
         column-gap:var(--spacing-8)}

  .object_contain{
    -o-object-fit:contain;
       object-fit:contain}

  .object_top_center{
    -o-object-position:top center;
       object-position:top center}

  .py_64px{
    padding-block:64px}

  .pt_var\(--pt\,_72px\){
    padding-top:var(--pt,72px)}

  .pb_var\(--pb\,_72px\){
    padding-bottom:var(--pb,72px)}

  .min-h_500px{
    min-height:500px}

  .text_\#1800D9{
    color:#1800D9}

  .tracking_-1\.14px{
    letter-spacing:-1.14px}

  .text_\#1E1E1E{
    color:#1E1E1E}

  .leading_1{
    line-height:1}

  .text_\#8C60FA{
    color:#8C60FA}

  .grid-cols_1fr{
    grid-template-columns:1fr}

  .gap_52px{
    gap:52px}

  .gap_32px{
    gap:32px}

  .mb_1{
    margin-bottom:var(--spacing-1)}

  .mb_2{
    margin-bottom:var(--spacing-2)}

  .text_\#8E69FB{
    color:#8E69FB}

  .font-style_italic{
    font-style:italic}

  .fs_18px{
    font-size:18px}

  .w_48px{
    width:48px}

  .h_48px{
    height:48px}

  .rounded_9999px{
    border-radius:9999px}

  .border_2px_solid_\#8E69FB{
    border:2px solid #8E69FB}

  .pt_44px{
    padding-top:44px}

  .pb_50px{
    padding-bottom:50px}

  .bg_linear-gradient\(180deg\,_\#2C2089_-61\.92\%\,_\#000A3A_102\.55\%\){
    background:linear-gradient(180deg,#2C2089 -61.92%,#000A3A 102.55%)}

  .\[\&_\>_\*\]\:max-w_100\%>*{
    max-width:100%}

  .\[\&_\>_\*\]\:flex_1_1_auto>*{
    flex:1 1 auto}

  .gap_5{
    gap:var(--spacing-5)}

  .\[\&_\>_\*\]\:flex_0_1_auto>*{
    flex:0 1 auto}

  .\[\&_\>_\*\]\:min-w_0>*{
    min-width:var(--sizes-0)}

  .bg_\#DDE2FF{
    background:#DDE2FF}

  .rounded_28px{
    border-radius:28px}

  .fs_15px{
    font-size:15px}

  .max-w_840px{
    max-width:840px}

  .\[\&_em\]\:bg_linear-gradient\(270deg\,_\#DAA6FB_0\%\,_rgba\(218\,_166\,_251\,_0\.00\)_94\.48\%\) em{
    background:linear-gradient(270deg,#DAA6FB 0%,rgba(218,166,251,0.00) 94.48%)}

  .\[\&_em\]\:rounded_0_50px_50px_0 em{
    border-radius:0 50px 50px 0}

  .\[\&_em\]\:leading_56px em{
    line-height:56px}

  .\[\&_em\]\:decoration_clone em{
    box-decoration-break:clone;
    -webkit-box-decoration-break:clone}

  .\[\&_em\]\:p_0px_10px em{
    padding:0px 10px}

  .\[\&_em\]\:m_0_-10px em{
    margin:0 -10px}

  .p_48px_24px_64px{
    padding:48px 24px 64px}

  .d_block{
    display:block}

  .text_rgba\(0\,_0\,_0\,_0\.1\){
    color:rgba(0,0,0,0.1)}

  .border-t_1px_solid_currentColor{
    border-top:1px solid currentColor}

  .fs_60px{
    font-size:60px}

  .tracking_-1\.32px{
    letter-spacing:-1.32px}

  .fs_23px{
    font-size:23px}

  .pt_80px{
    padding-top:80px}

  .pb_80px{
    padding-bottom:80px}

  .bg_center{
    background-position:center}

  .gap-y_76px{
    -moz-column-gap:76px;
         column-gap:76px}

  .gap-x_12{
    row-gap:var(--spacing-12)}

  .grid-cols_1fr_1fr{
    grid-template-columns:1fr 1fr}

  .fs_1xl{
    font-size:1xl}

  .bg_\#000A3A{
    background:#000A3A}

  .p_10{
    padding:var(--spacing-10)}

  .\[\&_caption\]\:w_100\% caption{
    width:100%}

  .bg-img_url\(\/stats-bg\.svg\)\,_linear-gradient\(91deg\,_\#3625C1_-30\.82\%\,_\#060086_100\%\){
    background-image:url(/stats-bg.svg),linear-gradient(91deg,#3625C1 -30.82%,#060086 100%)}

  .bg-img_linear-gradient\(91deg\,_\#3625C1_-30\.82\%\,_\#060086_100\%\){
    background-image:linear-gradient(91deg,#3625C1 -30.82%,#060086 100%)}

  .pt_var\(--pt\,_84px\){
    padding-top:var(--pt,84px)}

  .pb_var\(--pb\,_84px\){
    padding-bottom:var(--pb,84px)}

  .bg_center_60\%{
    background-position:center 60%}

  .py_140px{
    padding-block:140px}

  .fs_56px{
    font-size:56px}

  .leading_136\%{
    line-height:136%}

  .fs_32px{
    font-size:32px}

  .text_\#373737{
    color:#373737}

  .max-w_922px{
    max-width:922px}

  .gap_8{
    gap:var(--spacing-8)}

  .gap-y_6{
    -moz-column-gap:var(--spacing-6);
         column-gap:var(--spacing-6)}

  .gap-x_7{
    row-gap:var(--spacing-7)}

  .pt_var\(--pt\){
    padding-top:var(--pt)}

  .pb_var\(--pb\){
    padding-bottom:var(--pb)}

  .py_8{
    padding-block:var(--spacing-8)}

  .bg_linear-gradient\(180deg\,_\#1800D9_-22\.65\%\,_\#09004E_67\.42\%\){
    background:linear-gradient(180deg,#1800D9 -22.65%,#09004E 67.42%)}

  .mb_24px{
    margin-bottom:24px}

  .fs_26px{
    font-size:26px}

  .mb_16px{
    margin-bottom:16px}

  .mb_16{
    margin-bottom:var(--spacing-16)}

  .text_secondary\.1{
    color:var(--colors-secondary-1)}

  .pt_var\(--pt\,_90px\){
    padding-top:var(--pt,90px)}

  .pb_var\(--pb\,_140px\){
    padding-bottom:var(--pb,140px)}

  .bg_rgba\(31\,_20\,_120\,_1\){
    background:rgba(31,20,120,1)}

  .opacity_0\.1{
    opacity:0.1}

  .text_\#DFE5FF{
    color:#DFE5FF}

  .fs_46px{
    font-size:46px}

  .mb_10{
    margin-bottom:var(--spacing-10)}

  .fs_22px{
    font-size:22px}

  .text_\#BEC9FF{
    color:#BEC9FF}

  .w_1440{
    width:1440}

  .bottom_0{
    bottom:var(--spacing-0)}

  .w_958{
    width:958}

  .h_1121{
    height:1121}

  .h_100\%{
    height:100%}

  .z_1{
    z-index:1}

  .rounded_50\%\!{
    border-radius:50%!important}

  .bg_\#fffc\!{
    background-color:#fffc!important}

  .h_80px\!{
    height:80px!important}

  .w_80px\!{
    width:80px!important}

  .\[\&\:before\]\:transform_translate\(-30\%\,_-50\%\)_scale\(1\.5\)\!:before{
    transform:translate(-30%,-50%) scale(1.5)!important}

  .\[\&\:before\]\:border-l_primary\.2\!:before{
    border-left-color:var(--colors-primary-2)!important}

  .pt_var\(--ptm\,_var\(--pt\)\){
    padding-top:var(--ptm,var(--pt))}

  .pb_var\(--pbm\,_var\(--pb\)\){
    padding-bottom:var(--pbm,var(--pb))}

  .bg_rgba\(136\,_156\,_255\,_0\.29\){
    background-color:rgba(136,156,255,0.29)}

  .\[\&_label\]\:fs_12px label{
    font-size:12px}

  .flex_1_0{
    flex:1 0}

  .border_1px_solid_\#9FAFFF{
    border:1px solid #9FAFFF}

  .fs_10px{
    font-size:10px}

  .rounded_6px{
    border-radius:6px}

  .min-w_min\(100\%\,_300px\){
    min-width:min(100%,300px)}

  .flex_1{
    flex:1 1}

  .min-w_min\(100\%\,_200px\){
    min-width:min(100%,200px)}

  .text_start{
    text-align:start}

  .gap-x_12px\!{
    row-gap:12px!important}

  .flex-wrap_wrap{
    flex-wrap:wrap}

  .\[\&_em\]\:text_var\(--altColor\) em{
    color:var(--altColor)}

  .p_4px_16px{
    padding:4px 16px}

  .border_1px_solid_\#404EDB{
    border:1px solid #404EDB}

  .bg_\#E3E7FF{
    background:#E3E7FF}

  .p_0_1rem{
    padding:0 1rem}

  .appearance_revert{
    -moz-appearance:revert;
         appearance:revert;
    -webkit-appearance:revert}

  .p_0_16px{
    padding:0 16px}

  .items_stretch{
    align-items:stretch}

  .m_-40px_-32px{
    margin:-40px -32px}

  .rounded_20{
    border-radius:20px}

  .gap_9{
    gap:var(--spacing-9)}

  .py_10{
    padding-block:var(--spacing-10)}

  .px_5{
    padding-inline:var(--spacing-5)}

  .mx_-5{
    margin-inline:calc(var(--spacing-5) * -1)}

  .max-w_530px{
    max-width:530px}

  .p_6{
    padding:var(--spacing-6)}

  .top_-100px{
    top:-100px}

  .left_-16px{
    left:-16px}

  .max-w_calc\(100\%_\+_30px_-_85px\){
    max-width:calc(100% + 30px - 85px)}

  .text_\#C9C2FF{
    color:#C9C2FF}

  .border_\#9FAFFF{
    border-color:#9FAFFF}

  .leading_126\%{
    line-height:126%}

  .mb_4{
    margin-bottom:var(--spacing-4)}

  .text_\#EDEAFF{
    color:#EDEAFF}

  .ml_2{
    margin-left:var(--spacing-2)}

  .ring_primary{
    outline-color:primary}

  .flex_0{
    flex:0 1}

  .w_17px{
    width:17px}

  .h_17px{
    height:17px}

  .min-w_max-content{
    min-width:-moz-max-content;
    min-width:max-content}

  .h_11px{
    height:11px}

  .bg_\#6051D6{
    background:#6051D6}

  .bg_\#2817B0{
    background:#2817B0}

  .max-w_100\%{
    max-width:100%}

  .w_584px{
    width:584px}

  .py_26px{
    padding-block:26px}

  .px_16px{
    padding-inline:16px}

  .gap_4px{
    gap:4px}

  .mb_6{
    margin-bottom:var(--spacing-6)}

  .w_100px{
    width:100px}

  .h_100px{
    height:100px}

  .top_-42px{
    top:-42px}

  .self_start{
    align-self:start}

  .gap_1{
    gap:var(--spacing-1)}

  .mt_6px{
    margin-top:6px}

  .mt_30px{
    margin-top:30px}

  .gap_0{
    gap:var(--spacing-0)}

  .max-w_450px{
    max-width:450px}

  .p_12px_20px_40px{
    padding:12px 20px 40px}

  .rounded_20px{
    border-radius:20px}

  .filter_drop-shadow\(0px_7px_24px_rgba\(0\,_0\,_0\,_0\.10\)\){
    filter:drop-shadow(0px 7px 24px rgba(0,0,0,0.10))}

  .mt_auto{
    margin-top:auto}

  .max-w_400px{
    max-width:400px}

  .p_4{
    padding:var(--spacing-4)}

  .rounded_40px{
    border-radius:40px}

  .text_other\.1{
    color:var(--colors-other-1)}

  .py_6{
    padding-block:var(--spacing-6)}

  .text_rgba\(0\,_10\,_58\,_0\.80\){
    color:rgba(0,10,58,0.80)}

  .tracking_-0\.304px{
    letter-spacing:-0.304px}

  .tracking_-0\.38px{
    letter-spacing:-0.38px}

  .mb_3{
    margin-bottom:var(--spacing-3)}

  .fs_1\.2rem{
    font-size:1.2rem}

  .opacity_0\.8{
    opacity:0.8}

  .\[\&\.center\]\:text_center.center{
    text-align:center}

  .\[\&\.right\]\:text_right.right{
    text-align:right}

  .z_50{
    z-index:50}

  .bg_other\.1{
    background-color:var(--colors-other-1)}

  .min-h_120px{
    min-height:120px}

  .transition_background-color_\.3s{
    transition:background-color .3s}

  .floating\:pos_fixed:is([data-floating=true]){
    position:fixed}

  .floating\:bg_white:is([data-floating=true]){
    background-color:var(--colors-white)}

  .floating\:text_primary\.1:is([data-floating=true]){
    color:var(--colors-primary-1)}

  .floating\:transform_translateY\(-35px\):is([data-floating=true]){
    transform:translateY(-35px)}

  .light .light\:bg_white,.light\:bg_white.light{
    background-color:var(--colors-white)}

  .light .light\:text_primary\.1,.light\:text_primary\.1.light{
    color:var(--colors-primary-1)}

  .vis_hidden{
    visibility:hidden}

  .disabled\:vis_hidden:is(:disabled,[disabled],[data-disabled]){
    visibility:hidden}

  .text_\#DEDEDE{
    color:#DEDEDE}

  .fs_24px{
    font-size:24px}

  .mb_20{
    margin-bottom:var(--spacing-20)}

  .grid-flow_column{
    grid-auto-flow:column}

  .pl_2rem{
    padding-left:2rem}

  .text_left{
    text-align:left}

  .text_\#9FAFFF{
    color:#9FAFFF}

  .ring_secondary{
    outline-color:secondary}

  .w_32{
    width:var(--sizes-32)}

  .h_32{
    height:var(--sizes-32)}

  .py_2{
    padding-block:var(--spacing-2)}

  .mt_calc\(120px_\+_\(var\(--headerOffset\,_0px\)_\*_-1\)\){
    margin-top:calc(120px + (var(--headerOffset, 0px) * -1))}

  .top_0{
    top:var(--spacing-0)}

  .left_0{
    left:var(--spacing-0)}

  .right_0{
    right:var(--spacing-0)}

  .text_white{
    color:var(--colors-white)}

  .transform_translateY\(-100\%\){
    transform:translateY(-100%)}

  .mb_-24px{
    margin-bottom:-24px}

  .bg-img_url\(\/textures\/topBanner-bg\.svg\)\,_linear-gradient\(91deg\,_\#1800D9_0\%\,_\#3726C1_100\%\){
    background-image:url(/textures/topBanner-bg.svg),linear-gradient(91deg,#1800D9 0%,#3726C1 100%)}

  .bg_center_45\%{
    background-position:center 45%}

  .bg-repeat_no-repeat{
    background-repeat:no-repeat}

  .overflow_hidden{
    overflow:hidden}

  .w_230px{
    width:230px}

  .fs_12px{
    font-size:12px}

  .text_\#D6DDFF{
    color:#D6DDFF}

  .bottom_-1px{
    bottom:-1px}

  .max-w_7xl{
    max-width:var(--sizes-7xl)}

  .mx_auto{
    margin-inline:auto}

  .px_4{
    padding-inline:var(--spacing-4)}

  .py_40px{
    padding-block:40px}

  .gap_10{
    gap:var(--spacing-10)}

  .justify_space-between{
    justify-content:space-between}

  .w_20px{
    width:20px}

  .h_20px{
    height:20px}

  .pos_absolute{
    position:absolute}

  .top_50\%{
    top:50%}

  .transform_translateY\(-50\%\){
    transform:translateY(-50%)}

  .fill_\#666{
    fill:#666}

  .\[\&\[data-position\=left\]\]\:left_5px[data-position=left]{
    left:5px}

  .\[\&\[data-position\=right\]\]\:left_auto[data-position=right]{
    left:auto}

  .\[\&\[data-position\=right\]\]\:right_5px[data-position=right]{
    right:5px}

  .disabled\:fill_rgba\(255\,_255\,_255\,_0\.5\):is(:disabled,[disabled],[data-disabled]){
    fill:rgba(255,255,255,0.5)}

  .p_10px_0{
    padding:10px 0}

  .w_10px{
    width:10px}

  .h_10px{
    height:10px}

  .bg_\#c5c5c5{
    background:#c5c5c5}

  .rounded_50\%{
    border-radius:50%}

  .m_0_5px{
    margin:0 5px}

  .p_5px{
    padding:5px}

  .leading_133\%{
    line-height:133%}

  .flex_row{
    flex-direction:row}

  .\[\&_a\]\:text-decor_underline a{
    text-decoration:underline}

  .\[\&_a\]\:transition_opacity_0\.3s a{
    transition:opacity 0.3s}

  .flex_0_0_auto{
    flex:0 0 auto}

  .w_4{
    width:var(--sizes-4)}

  .h_4{
    height:var(--sizes-4)}

  .rounded_4px{
    border-radius:4px}

  .border_1px_solid_\#000{
    border:1px solid #000}

  .order_1{
    order:1}

  .grid-cols_repeat\(1\,_minmax\(0\,_1fr\)\){
    grid-template-columns:repeat(1,minmax(0,1fr))}

  .gap_40px{
    gap:40px}

  .text_primary\.1{
    color:var(--colors-primary-1)}

  .justify-items_center{
    justify-items:center}

  .text_center{
    text-align:center}

  .mb_40px{
    margin-bottom:40px}

  .text_nowrap{
    text-wrap:nowrap}

  .pos_relative{
    position:relative}

  .gap_4{
    gap:var(--spacing-4)}

  .shrink_0{
    flex-shrink:0}

  .rounded_16px{
    border-radius:16px}

  .bg-repeat_repeat-x{
    background-repeat:repeat-x}

  .bg_200\%_100\%{
    background-size:200% 100%}

  .cursor_pointer{
    cursor:pointer}

  .transition_background_0\.3s\,_box-shadow_0\.3s{
    transition:background 0.3s,box-shadow 0.3s}

  .bg-gradient_button\.1{
    background-image:var(--gradients-button-1)}

  .bg-gradient_button\.1,.bg-gradient_button\.2{
    --gradient-stops:var(--gradient-from),var(--gradient-to);
    --gradient:var(--gradient-via-stops,var(--gradient-stops))}

  .bg-gradient_button\.2{
    background-image:var(--gradients-button-2)}

  .text_primary\.4{
    color:var(--colors-primary-4)}

  .before\:bg_token\(gradients\.button\.3\):before{
    background:var(--gradients-button-3)}

  .before\:bg_token\(gradients\.button\.4\):before{
    background:var(--gradients-button-4)}

  .before\:d_block:before{
    display:block}

  .before\:content_\"\":before{
    content:""}

  .before\:pos_absolute:before{
    position:absolute}

  .before\:top_0:before{
    top:var(--spacing-0)}

  .before\:left_0:before{
    left:var(--spacing-0)}

  .before\:right_0:before{
    right:var(--spacing-0)}

  .before\:bottom_0:before{
    bottom:var(--spacing-0)}

  .before\:rounded_16px:before{
    border-radius:16px}

  .before\:border_2px_solid_transparent:before{
    border:2px solid transparent}

  .before\:-webkit-mask_linear-gradient\(\#fff_0_0\)_padding-box\,_linear-gradient\(\#fff_0_0\):before{
    -webkit-mask:linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0)}

  .before\:-webkit-mask-composite_destination-out:before{
    -webkit-mask-composite:destination-out}

  .before\:mask-composite_exclude:before{
    -webkit-mask-composite:xor;
            mask-composite:exclude}

  .min-h_62px{
    min-height:62px}

  .p_16px_40px{
    padding:16px 40px}

  .p_16px_24px{
    padding:16px 24px}

  .text_\#000{
    color:#000}

  .font_500{
    font-weight:500}

  .leading_150\%{
    line-height:150%}

  .tracking_-0\.176px{
    letter-spacing:-0.176px}

  .\--react-international-phone-border-radius_8px{
    --react-international-phone-border-radius:8px }

  .\--react-international-phone-border-color_transparent{
    --react-international-phone-border-color:transparent }

  .\--react-international-phone-background-color_white{
    --react-international-phone-background-color:white }

  .\--react-international-phone-text-color_\#0008{
    --react-international-phone-text-color:#0008 }

  .\--react-international-phone-selected-dropdown-item-background-color_\#E6EBFF{
    --react-international-phone-selected-dropdown-item-background-color:#E6EBFF }

  .\--react-international-phone-country-selector-background-color-hover_\#E6EBFF{
    --react-international-phone-country-selector-background-color-hover:#E6EBFF }

  .w_100\%{
    width:100%}

  .d_inline-flex{
    display:inline-flex}

  .justify_center{
    justify-content:center}

  .mb_30px{
    margin-bottom:30px}

  .p_8px_24px{
    padding:8px 24px}

  .rounded_90px{
    border-radius:90px}

  .border_2px_solid{
    border:2px solid}

  .border_white{
    border-color:var(--colors-white)}

  .fs_20px{
    font-size:20px}

  .font-style_normal{
    font-style:normal}

  .h_45px{
    height:45px}

  .min-w_120px{
    min-width:120px}

  .border_none{
    border:var(--borders-none)}

  .rounded_8px{
    border-radius:8px}

  .bg_white{
    background:var(--colors-white)}

  .shadow_0px_1px_3px_1px_\#00000026{
    box-shadow:0px 1px 3px 1px #00000026}

  .transition_background_280ms{
    transition:background 280ms}

  .flex_0_0_calc\(\(100\%_-_var\(--columnWidth\)\)_\/_var\(--columns\)\){
    flex:0 0 calc((100% - var(--columnWidth)) / var(--columns))}

  .w_20{
    width:var(--sizes-20)}

  .h_20{
    height:var(--sizes-20)}

  .d_grid{
    display:grid}

  .items_center{
    align-items:center}

  .grid-cols_auto_1fr{
    grid-template-columns:auto 1fr}

  .gap_2{
    gap:var(--spacing-2)}

  .min-w_180px{
    min-width:180px}

  .max-w_265px{
    max-width:265px}

  .border-r_1px_solid_rgba\(0\,_0\,_0\,_0\.1\){
    border-right:1px solid rgba(0,0,0,0.1)}

  .pr_6{
    padding-right:var(--spacing-6)}

  .m_46px_0_56px{
    margin:46px 0 56px}

  .\[\&\:last-child\]\:border-r_0:last-child{
    border-right:0}

  .p_0_16px_16px{
    padding:0 16px 16px}

  .border-b_1px_solid_rgba\(0\,_0\,_0\,_0\.1\){
    border-bottom:1px solid rgba(0,0,0,0.1)}

  .pb_6{
    padding-bottom:var(--spacing-6)}

  .text_uppercase{
    text-transform:uppercase}

  .font_700{
    font-weight:700}

  .w_16{
    width:var(--sizes-16)}

  .h_16{
    height:var(--sizes-16)}

  .text_\#000A3A{
    color:#000A3A}

  .leading_normal{
    line-height:var(--line-heights-normal)}

  .font_400{
    font-weight:400}

  .fs_16px{
    font-size:16px}

  .font_600{
    font-weight:600}

  .fs_14px{
    font-size:14px}

  .w_237{
    width:237px}

  .h_212{
    height:212px}

  .m_1{
    margin:var(--spacing-1)}

  .d_flex{
    display:flex}

  .items_start{
    align-items:start}

  .gap_10px{
    gap:10px}

  .flex_column{
    flex-direction:column}

  .gap_6{
    gap:var(--spacing-6)}

  .text_\#6332DF{
    color:#6332DF}

  .font_heading{
    font-family:var(--fonts-heading)}

  .w_15px{
    width:15px}

  .h_15px{
    height:15px}

  .fill_none{
    fill:none}

  .stroke_currentcolor{
    stroke:currentcolor}

  .ml_3px{
    margin-left:3px}

  .fill_\#DEDEDE{
    fill:#DEDEDE}

  .font_bold{
    font-weight:var(--font-weights-bold)}

  .currentPage\:text_\#9482FC\![aria-current=page]{
    color:#9482FC!important}

  .flex_0_0_32{
    flex:0 0 32}

  .w_86{
    width:86px}

  .h_86{
    height:86px}

  .self_stretch{
    align-self:stretch}

  .justify-self_stretch{
    justify-self:stretch}

  .flex_0_0_16{
    flex:0 0 16}

  .\[\&\:focus-within\]\:bg_white:focus-within{
    background:var(--colors-white)}

  .\[\&\:not\(\:focus-within\)\:is\(\:hover\,_\:active\)\]\:bg_\#E6EBFF:not(:focus-within):is(:hover,:active){
    background:#E6EBFF}

  .focus\:ring_none:is(:focus,[data-focus]){
    outline:var(--borders-none)}

  .\[\&_input\]\:\[\&\:not\(\:focus\)\:\:placeholder\]\:text_\#0008 input:not(:focus)::-moz-placeholder{
    color:#0008}

  .\[\&_input\]\:\[\&\:not\(\:focus\)\:\:placeholder\]\:text_\#0008 input:not(:focus)::placeholder{
    color:#0008}

  .\[\&_input\]\:\[\&\:focus\]\:ring_none input:focus{
    outline:var(--borders-none)}

  .\[\&_input\]\:\[\&\:focus\:\:placeholder\]\:text_\#8596EE input:focus::-moz-placeholder{
    color:#8596EE}

  .\[\&_input\]\:\[\&\:focus\:\:placeholder\]\:text_\#8596EE input:focus::placeholder{
    color:#8596EE}

  .\[\&\:not\(\:focus\)\:\:placeholder\]\:text_\#0008:not(:focus)::-moz-placeholder{
    color:#0008}

  .\[\&\:not\(\:focus\)\:\:placeholder\]\:text_\#0008:not(:focus)::placeholder{
    color:#0008}

  .\[\&\:focus\]\:ring_none:focus{
    outline:var(--borders-none)}

  .\[\&\:focus\:\:placeholder\]\:text_\#8596EE:focus::-moz-placeholder{
    color:#8596EE}

  .\[\&\:focus\:\:placeholder\]\:text_\#8596EE:focus::placeholder{
    color:#8596EE}

  .\[\&_a\]\:\[\&\:hover\]\:opacity_0\.7 a:hover{
    opacity:0.7}

  .hover\:shadow_0px_4px_17px_0px_rgba\(140\,_96\,_250\,_0\.80\):is(:hover,[data-hover]){
    box-shadow:0px 4px 17px 0px rgba(140,96,250,0.80)}

  .hover\:shadow_0px_4px_17px_0px_rgba\(78\,_202\,_224\,_0\.40\):is(:hover,[data-hover]){
    box-shadow:0px 4px 17px 0px rgba(78,202,224,0.40)}

  .hover\:shadow_0px_4px_17px_0px_rgba\(180\,_150\,_255\,_0\.40\):is(:hover,[data-hover]){
    box-shadow:0px 4px 17px 0px rgba(180,150,255,0.40)}

  .hover\:bg_100\%_0:is(:hover,[data-hover]){
    background-position:100% 0}

  .hover\:bg_-100\%_0:is(:hover,[data-hover]){
    background-position:-100% 0}

  .hover\:text-decor_underline:is(:hover,[data-hover]){
    text-decoration:underline}

  .hover\:text_primary\.3:is(:hover,[data-hover]){
    color:var(--colors-primary-3)}

  .active\:bg_\#000:is(:active,[data-active]){
    background:#000}

  @layer compositions{
    .textStyle_mediaText\.title{
      font-family:var(--fonts-heading);
      font-size:22px;
      font-style:normal;
      font-weight:600;
      line-height:150%}

    .textStyle_mediaText\.subtitle{
      font-family:var(--fonts-body);
      font-size:14px;
      font-style:normal;
      font-weight:500;
      line-height:133%}

    .textStyle_button\.large{
      font-size:20px}

    .textStyle_button\.large,.textStyle_button\.medium{
      font-family:var(--fonts-body);
      font-weight:600;
      line-height:1.5em;
      text-transform:uppercase}

    .textStyle_button\.medium{
      font-size:14px}

    @media screen and (min-width: 64em){
      .textStyle_mediaText\.title{
        font-size:46px}

      .textStyle_mediaText\.subtitle{
        font-size:22px}}}

  @media screen and (min-width: 48em){
    .md\:items_center{
      align-items:center}

    .md\:self_end{
      align-self:end}

    .md\:ml_auto{
      margin-left:auto}

    .md\:gap_6{
      gap:var(--spacing-6)}

    .md\:flex_row{
      flex-direction:row}

    .md\:direction_row{
      direction:row}

    .md\:mb_8{
      margin-bottom:var(--spacing-8)}

    .md\:fs_71px{
      font-size:71px}

    .md\:gap_64px{
      gap:64px}

    .md\:max-w_66\%{
      max-width:66%}

    .md\:mb_28px{
      margin-bottom:28px}

    .md\:fs_23px{
      font-size:23px}

    .md\:rounded_30{
      border-radius:30px}

    .md\:py_5{
      padding-block:var(--spacing-5)}

    .md\:px_10{
      padding-inline:var(--spacing-10)}

    .md\:fs_30px{
      font-size:30px}

    .md\:fs_20px{
      font-size:20px}

    .md\:fs_24px{
      font-size:24px}

    .md\:justify_space-around{
      justify-content:space-around}

    .\[\&_em\]\:md\:d_inline-block em{
      display:inline-block}

    .\[\&_em\]\:md\:p_2px_10px_6px_10px em{
      padding:2px 10px 6px 10px}

    .\[\&_em\]\:md\:m_-2px_-10px_-6px_-10px em{
      margin:-2px -10px -6px -10px}

    .md\:fs_56px{
      font-size:56px}

    .md\:grid-cols_repeat\(2\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(2,minmax(0,1fr))}

    .md\:fs_26px{
      font-size:26px}

    .md\:leading_32px{
      line-height:32px}

    .md\:fs_14px{
      font-size:14px}

    .md\:leading_133\%{
      line-height:133%}

    .md\:px_6{
      padding-inline:var(--spacing-6)}}

  @media screen and (min-width: 64em){
    .lg\:p_44px_44px_80px{
      padding:44px 44px 80px}

    .lg\:fs_32px{
      font-size:32px}

    .lg\:tracking_-0\.608px{
      letter-spacing:-0.608px}

    .lg\:mb_56px{
      margin-bottom:56px}

    .lg\:fs_36px{
      font-size:36px}

    .lg\:pt_112px{
      padding-top:112px}

    .lg\:py_20{
      padding-block:var(--spacing-20)}

    .lg\:py_40px{
      padding-block:40px}

    .lg\:fs_23px{
      font-size:23px}

    .lg\:leading_32px{
      line-height:32px}

    .lg\:leading_133\%{
      line-height:133%}

    .lg\:grid-cols_1\.5fr_1fr{
      grid-template-columns:1.5fr 1fr}

    .lg\:gap_20{
      gap:var(--spacing-20)}

    .lg\:mb_140px{
      margin-bottom:140px}

    .lg\:gap-x_8{
      row-gap:var(--spacing-8)}

    .lg\:my_16px{
      margin-block:16px}

    .lg\:justify_center{
      justify-content:center}

    .lg\:gap-x_108px{
      row-gap:108px}

    .lg\:max-w_572px{
      max-width:572px}

    .lg\:p_132px_120px_180px_52px{
      padding:132px 120px 180px 52px}

    .lg\:p_132px_40px_120px_148px{
      padding:132px 40px 120px 148px}

    .lg\:mb_45px{
      margin-bottom:45px}

    .lg\:fs_30px{
      font-size:30px}

    .lg\:tracking_-0\.57px{
      letter-spacing:-0.57px}

    .lg\:tracking_-0\.38px{
      letter-spacing:-0.38px}

    .lg\:fs_28px{
      font-size:28px}

    .lg\:grid-cols_5fr_4fr{
      grid-template-columns:5fr 4fr}

    .lg\:h_var\(--height\,_40px\){
      height:var(--height,40px)}

    .lg\:p_30px_20px_40px{
      padding:30px 20px 40px}

    .lg\:rounded_20px{
      border-radius:20px}

    .lg\:p_16px_32px{
      padding:16px 32px}

    .lg\:fs_40px{
      font-size:40px}

    .lg\:mb_16px{
      margin-bottom:16px}

    .lg\:fs_25px{
      font-size:25px}

    .lg\:gap_32px{
      gap:32px}

    .lg\:w_220\%{
      width:220%}

    .lg\:mt_60px{
      margin-top:60px}

    .lg\:items_stretch{
      align-items:stretch}

    .lg\:gap-x_80px{
      row-gap:80px}

    .lg\:grid-cols_1\.2fr_1fr{
      grid-template-columns:1.2fr 1fr}

    .lg\:mb_6{
      margin-bottom:var(--spacing-6)}

    .lg\:mb_8{
      margin-bottom:var(--spacing-8)}

    .lg\:gap_8{
      gap:var(--spacing-8)}

    .lg\:mb_16{
      margin-bottom:var(--spacing-16)}

    .lg\:gap_6{
      gap:var(--spacing-6)}

    .lg\:w_68px{
      width:68px}

    .lg\:h_68px{
      height:68px}

    .lg\:p_10{
      padding:var(--spacing-10)}

    .lg\:grid-cols_repeat\(3\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(3,minmax(0,1fr))}

    .lg\:fs_46px{
      font-size:46px}

    .lg\:fs_22px{
      font-size:22px}

    .lg\:mb_48px{
      margin-bottom:48px}

    .lg\:pt_var\(--pt\){
      padding-top:var(--pt)}

    .lg\:pb_var\(--pb\){
      padding-bottom:var(--pb)}

    .\[\&_label\]\:lg\:fs_16px label{
      font-size:16px}

    .lg\:fs_18px{
      font-size:18px}

    .lg\:rounded_16px{
      border-radius:16px}

    .lg\:p_16px_40px{
      padding:16px 40px}

    .lg\:items_start{
      align-items:start}

    .lg\:max-w_calc\(100\%_\+_30px_-_185px\){
      max-width:calc(100% + 30px - 185px)}

    .lg\:fs_48px{
      font-size:48px}

    .lg\:fs_19px{
      font-size:19px}

    .lg\:fs_24px{
      font-size:24px}

    .hide_lg{
      display:none}

    .lg\:py_48px{
      padding-block:48px}

    .lg\:px_30px{
      padding-inline:30px}

    .lg\:gap_8px{
      gap:8px}

    .lg\:w_200px{
      width:200px}

    .lg\:h_200px{
      height:200px}

    .lg\:top_-72px{
      top:-72px}

    .lg\:py_8{
      padding-block:var(--spacing-8)}

    .lg\:fs_16px{
      font-size:16px}

    .lg\:fs_20px{
      font-size:20px}

    .lg\:mb_4{
      margin-bottom:var(--spacing-4)}

    .lg\:left_24px{
      left:24px}

    .lg\:px_8{
      padding-inline:var(--spacing-8)}

    .lg\:grid-cols_auto_1fr{
      grid-template-columns:auto 1fr}

    .lg\:flex_row{
      flex-direction:row}

    .lg\:items_center{
      align-items:center}

    .lg\:order_-1{
      order:-1}

    .lg\:order_1{
      order:1}

    .lg\:grid-cols_repeat\(2\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(2,minmax(0,1fr))}

    .lg\:fs_60px{
      font-size:60px}

    .lg\:mb_96px{
      margin-bottom:96px}}

  @media screen and (min-width: 64em) and (max-width: 79.9975em){
    .lgOnly\:grid-cols_repeat\(2\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(2,minmax(0,1fr))}}

  @media screen and (min-width: 80em){
    .xl\:grid-cols_1fr_1\.15fr{
      grid-template-columns:1fr 1.15fr}

    .hide_xl{
      display:none}

    .xl\:grid-cols_repeat\(2\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(2,minmax(0,1fr))}

    .xl\:order_1{
      order:1}

    .xl\:grid-cols_1fr_1\.35fr{
      grid-template-columns:1fr 1.35fr}

    .xl\:grid-cols_repeat\(3\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(3,minmax(0,1fr))}

    .xl\:grid-cols_repeat\(4\,_minmax\(0\,_1fr\)\){
      grid-template-columns:repeat(4,minmax(0,1fr))}}

  @media screen and (max-width: 79.9975em){
    .xlDown\:mx_auto{
      margin-inline:auto}

    .xlDown\:vis_hidden{
      visibility:hidden}

    .xlDown\:vis_none{
      visibility:none}

    .show_xl{
      display:none}}

  @media screen and (max-width: 63.9975em){
    .lgDown\:font_400{
      font-weight:400}

    .lgDown\:tracking_-0\.228px{
      letter-spacing:-0.228px}

    .lgDown\:w_80px{
      width:80px}

    .lgDown\:px_6{
      padding-inline:var(--spacing-6)}

    .lgDown\:overflow_auto{
      overflow:auto}

    .lgDown\:fs_28px{
      font-size:28px}

    .lgDown\:font_700{
      font-weight:700}

    .lgDown\:fs_18px{
      font-size:18px}

    .lgDown\:tracking_-0\.198px{
      letter-spacing:-0.198px}

    .show_lg{
      display:none}

    .lgDown\:w_120px{
      width:120px}

    .lgDown\:right_24px{
      right:24px}

    .lgDown\:pt_4{
      padding-top:var(--spacing-4)}

    .lgDown\:pb_6{
      padding-bottom:var(--spacing-6)}

    .lgDown\:max-w_570px{
      max-width:570px}

    .lgDown\:before\:rounded_6px:before{
      border-radius:6px}

    .lgDown\:before\:rounded_10px:before{
      border-radius:10px}

    .lgDown\:fs_10px{
      font-size:10px}

    .lgDown\:p_8px_12px{
      padding:8px 12px}

    .lgDown\:rounded_6px{
      border-radius:6px}

    .lgDown\:p_10px_24px{
      padding:10px 24px}

    .lgDown\:rounded_10px{
      border-radius:10px}

    .lgDown\:mb_12px{
      margin-bottom:12px}

    .lgDown\:p_4px_12px{
      padding:4px 12px}

    .lgDown\:fs_12px{
      font-size:12px}}

  @media screen and (max-width: 47.9975em){
    .mdDown\:fs_12px{
      font-size:12px}

    .mdDown\:\[\&\:nth-child\(n\+4\)\]\:d_none:nth-child(n+4){
      display:none}

    .mdDown\:d_contents{
      display:contents}

    .show_md{
      display:none}

    .mdDown\:flex-wrap_wrap{
      flex-wrap:wrap}}}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3794f505ceb4aef5-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/320d9f5d177d6ec2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ca9e8d8193aed290-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/80512c49369d7ad3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0d580af215996300-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/14254a1c498c2b09-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4036a8cc6ad3520f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c7128a8004343716-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c4a2ca76cbcd952a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_10f679';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_10f679 {font-family: '__Roboto_10f679', '__Roboto_Fallback_10f679';font-weight: 400;font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ae6a2fe692ea80aa-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c99ef75952aca458-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bd907a34e79d0cf5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7c1535108079abc4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8727a9eb9a9ed107-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cf1f69a0c8aed54d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_505c36';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_505c36';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_505c36 {font-family: '__Poppins_505c36', '__Poppins_Fallback_505c36'
}

